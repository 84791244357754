.containerKarten {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.containerDevice {
  display: grid;
  width: 100vw;
  align-content: center;
  justify-content: center; /* Zentriert die .containerRandom-Div horizontal */
}
.containerRandom {
  display: grid;
  width: 600px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
}

/* Samsung Galaxy Tab 3 20,3 cm (8 Zoll) Tablet (1,5Ghz, Dual Cor, 1,5GB RAM, 16 GB interner Speicher,  5 Megalpixle, Kamera, WiFi, Android 4,2)
Auflösung galli: 1.280 x 800 Pixel  */

/* Auflösung Mekong: 2160*1440  */
/* Auflösung Handy Galaxy S20: 360*740  */

/* Media Query für Tablets im Querformat */
/* Media Query for tablets in landscape orientation */
@media screen and (orientation: landscape) and (max-width: 1280px) {
  .containerDevice {
    justify-content: flex-end;
    padding-top: 150px;
  }
}

@media screen and (orientation: portrait) and (max-width: 768px) {
  .containerKarten {
    width: 320px;
  }
  .containerRandom {
    width: 300px;
  }
}
