.containertext {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(90vw, 1fr));
  gap: 1px;
  padding: 15px;
}

.containertext h6 {
  padding-bottom: 0px;
  margin-block-start: 0;
  margin-block-end: 0;
}

.containertext h3 {
  padding-bottom: 0px;
  margin-block-start: 0;
  margin-block-end: 0;
}
