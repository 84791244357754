.cardVokabelkarte {
  display: grid;
  grid-template-columns: 1fr;
  align-content: start;
  gap: 1px;
  padding: 20px;
  position: relative;
  background-color: blue;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  color: white;
  padding: 15px;
}

.iconNav {
  display: grid;
  grid-template-columns: repeat(5, auto); /* Automatische Breite für jede Spalte */
  gap: 3px; /* Abstand zwischen den Spalten */
  padding-left: auto; /* Automatischer Abstand auf der linken Seite */
  justify-content: end; /* Icons am rechten Rand ausrichten */

  position: absolute; /* Unteren Inhalt auf absolute Position setzen */
  bottom: 0; /* Am unteren Rand der Card ausrichten */
  right: 0; /* Am rechten Rand der Card ausrichten */
}
.edit-link {
  padding: 2px;
}
.delete-link {
  padding: 2px;
}
.megaphone-link {
  padding: 2px;
}
.megaphone-link a {
  padding: 2px;
}
.pons-link {
  padding: 2px;
}
.pons-link a {
  padding: 2px;
}
.verb-link {
  padding: 2px;
}
.verb-link a {
  padding: 2px;
}

.line {
  display: flex;
  align-items: left;
  margin-bottom: 5px;
}

.separator {
  margin-right: 5px;
}

.uebersetzung {
  display: none;
}

.card:hover .uebersetzung {
  display: block;
}

.label {
  font-weight: bold;
  margin-right: 5px;
}

.value {
  font-weight: normal;
  margin-bottom: 30px;
}

.wort {
  font-weight: bold;
}

.id {
  align-items: right;
  justify-self: end;
}

.value:hover {
  cursor: pointer;
}

.value:hover .modal {
  opacity: 1;
  pointer-events: auto;
}

.modalVokabelkarte {
  position: fixed;
  top: 5%;
  left: 5%;
  transform: translate(-5%, -5%);
  background-color: yellow;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
  /* opacity: 0; */
  pointer-events: none;
  /* transition: opacity 0.3s ease; */
  max-width: 400px;
}

.modalVokabelkarte-content {
  color: #333;
}

/* Media Query für Bildschirmgrößen kleiner als 768px (z. B. Handys) */
@media screen and (max-width: 768px) {
  .modalVokabelkarte {
    top: 20%;
    max-width: 300px;
  }
}
