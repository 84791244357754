/* VocsList.css */

.voc-list {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 20px; /* Padding links */
  padding-right: 20px; /* Padding rechts */
}

.voc-list li {
  margin-bottom: 10px;
}

.voc-item {
  text-align: right; /* Rechtsbündige Ausrichtung */
  margin-bottom: 10px;
  width: 90vw; /* Feste Breite */
  height: 90px; /* Feste Höhe */
}

.voc-word {
  font-weight: bold;
}

.voc-translation {
  display: none; /* Standardmäßig ausblenden */
}

.voc-list li:hover .voc-translation {
  display: inline; /* Bei Hover anzeigen */
  color: #888;
  margin-left: 5px;
}
