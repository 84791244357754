.nav {
  display: grid;
  align-items: start; /* Andere Elemente werden oben ausgerichtet */
  background-color: #333;
  color: #fff;
  padding: 10px;
}

.nav-group {
  display: grid; /* Hier verwenden wir ein neues Grid für die custom-links */
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
  gap: 10px; /* Abstand zwischen den custom-links */
}
.nav-group-right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start; /* Elemente oben ausrichten */
  flex-wrap: wrap;
  gap: 10px; /* Abstand zwischen den custom-links */
  margin-top: 5px;
}

/* Stil für die einzelnen Elemente innerhalb der .nav-group-right */
.nav-group-right > * {
  min-width: 100px; /* Mindestbreite für die Elemente */
  align-self: flex-start; /* Elemente oben ausrichten */
}

.xsite-title {
  display: flex;
  align-items: center;
  height: 100%;
}

.xsite-title a {
  color: #fff;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
}

.xsite-title a:hover {
  background-color: #555;
}

/* Stile für die Haupt-Navigationselemente */
ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin: 0 10px;
}

a {
  color: #fff;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
}

a:hover {
  background-color: #555;
}

.select {
  padding: 5px;
  border-radius: 5px;
}

.searchStringField {
  max-width: 60px;
  margin-left: 5px;
  margin-right: 5px;
}

.logout-button {
  background-color: blue;
  color: white;
}

.logout-button:hover {
  background-color: lightblue;
}

/* Media Query für Bildschirmgrößen kleiner als 768px (z. B. Handys) */
/* @media screen and (max-width: 768px) {
  .nav {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 5px;
    background-color: #333;
    color: #fff;
    justify-content: space-around;
    padding: 5px;
  }
} */
